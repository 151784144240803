import {
  createContext,
  type PropsWithChildren,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { Navigate, useLocation, useRouteLoaderData } from 'react-router-dom';
import { createResponseError } from '@shared/api/createResponseError';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { isErrorResponse } from '@shared/types/apiHelpers';
import {
  getRestaurantDetailsById,
  type RestaurantDetails,
} from 'webReservations/restaurant/apiHelpers';
import { LANDING_PATH } from '../paths';

export interface RestaurantContextState {
  restaurantDetails: RestaurantDetails;
  setRestaurantDetails: (restaurantDetails: RestaurantDetails) => void;
}

const RestaurantContext = createContext<RestaurantContextState | null>(null);
RestaurantContext.displayName = 'Restaurant';

export const useRestaurantContext = () => useDefinedContext(RestaurantContext);

export const RestaurantContextProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const ref = useRef<RestaurantDetails>();
  const [, forceRender] = useReducer((n: number) => n + 1, 0);

  const locationState = location.state as undefined | { restaurantId?: string };
  const locationStateRestaurantId = locationState?.restaurantId;
  const {
    data: locationStateRestaurant,
    isPending: isLocationStateRestaurantLoading,
  } = useAbortEffect(
    {
      effect: async () => {
        if (!locationStateRestaurantId) {
          return undefined;
        }
        const response = await getRestaurantDetailsById(
          locationStateRestaurantId,
        );
        if (isErrorResponse(response)) {
          throw createResponseError(response);
        }
        return response;
      },
      throwOnError: true,
    },
    [locationStateRestaurantId],
  );

  useMemo(() => {
    if (locationStateRestaurant) {
      ref.current = locationStateRestaurant;
    }
  }, [locationStateRestaurant]);

  const loaderRestaurant = useRouteLoaderData('restaurant') as
    | RestaurantDetails
    | undefined;

  useMemo(() => {
    if (loaderRestaurant) {
      ref.current = loaderRestaurant;
    }
  }, [loaderRestaurant]);

  const value = useMemo((): RestaurantContextState | null => {
    if (!ref.current) {
      return null;
    }
    return {
      restaurantDetails: ref.current,
      setRestaurantDetails: (nextRestaurantDetails) => {
        ref.current = nextRestaurantDetails;
        forceRender();
      },
    };
  }, [ref.current]);

  if (!value) {
    if (isLocationStateRestaurantLoading) {
      return null;
    }
    return <Navigate to={LANDING_PATH} />;
  }

  return (
    <RestaurantContext.Provider value={value}>
      {children}
    </RestaurantContext.Provider>
  );
};
