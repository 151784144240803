import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { PRODUCTION } from '@shared/environments';
import { sentryCreateBrowserRouter } from '@utils/sentry';
import { AvailabilityContextProvider } from './context/AvailabilityContext';
import { RestaurantContextProvider } from './context/RestaurantContext';
import { AccountsLayout } from './accounts/AccountsLayout';
import { BalancePage } from './accounts/BalancePage';
import { NotificationsPage } from './accounts/NotificationsPage';
import { PaymentMethodsPage } from './accounts/PaymentMethodsPage';
import { IsAuthenticated } from './auth/IsAuthenticated';
import { FetchLinkToken, PlaidLink } from './bankAccounts';
import { OfferCheckoutConfirmationPage } from './checkout/OfferCheckoutConfirmationPage';
import { OfferCheckoutLayout } from './checkout/OfferCheckoutLayout';
import { OfferCheckoutPage } from './checkout/OfferCheckoutPage';
import { EmailVerificationFailPage } from './email/EmailVerificationFailPage';
import { EmailVerificationSuccessPage } from './email/EmailVerificationSuccessPage';
import { ErrorHandler } from './errors/ErrorHandler';
import { NotFoundPage } from './errors/NotFound/NotFoundPage';
import { FAQPage } from './faq/FAQPage';
import { LandingPage } from './landing/LandingPage';
import { offerLoader, offersOutLoader, restaurantLoader } from './loaders';
import { restaurantsLoader } from './loaders/restaurantsLoader';
import { userAccountLoader } from './loaders/userAccountLoader';
import { OfferOutDetailsPage } from './offers/OfferOutDetailsPage';
import { OffersInPage } from './offers/OffersInPage';
import { OffersLayout } from './offers/OffersLayout';
import { OffersOutPage } from './offers/OffersOutPage';
import {
  ABOUT_US_PATH,
  ACCOUNT_BALANCE_PATH,
  ACCOUNT_NOTIFICATIONS_PATH,
  ACCOUNT_PAYMENT_METHODS_PATH,
  ACCOUNT_ROOT_PATH,
  CHECKOUT_CONFIRMATION_TERMINAL_PATH,
  CHECKOUT_ROOT_PATH,
  EMAIL_VERIFICATION_FAIL_PATH,
  EMAIL_VERIFICATION_SUCCESS_PATH,
  FAQ_PATH,
  LANDING_PATH,
  OFFER_CHECKOUT_CONFIRMATION_TERMINAL_PATH,
  OFFER_CHECKOUT_ROOT_PATH,
  OFFER_OUT_DETAILS_PATH,
  OFFERS_IN_PATH,
  OFFERS_OUT_PATH,
  OFFERS_ROOT_PATH,
  PLAID_AUTH_TERMINAL_PATH,
  PLAID_ROOT_PATH,
  PRIVACY_POLICY_PATH,
  REDIRECT_PATH,
  RESERVATION_DETAILS_PATH,
  RESERVATIONS_CONFIRMED_PATH,
  RESERVATIONS_INACTIVE_PATH,
  RESERVATIONS_ROOT_PATH,
  RESTAURANT_DEMO_PATH,
  RESTAURANT_REVENUE_CALCULATOR_PATH,
  RESTAURANTS_PATH,
  TERMS_OF_SERVICE_PATH,
} from './paths';
import { ReservationDetailsPage } from './reservationDetails/ReservationDetailsPage';
import { ConfirmedPage } from './reservations/ConfirmedPage';
import { InactivePage } from './reservations/InactivePage';
import { ReservationsLayout } from './reservations/ReservationsLayout';
import { IsDiscoverable } from './restaurant/IsDiscoverable';
import { RestaurantAboutPage } from './restaurant/RestaurantAboutPage';
import { RestaurantsPage } from './restaurant/RestaurantsPage';
import { Root } from './Root';

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<ErrorHandler withoutLayout />}>
      <Route errorElement={<ErrorHandler />}>
        <Route element={<LandingPage />} path={LANDING_PATH} />
        {process.env.ENV !== PRODUCTION && (
          <Route
            element={<RestaurantsPage />}
            path={RESTAURANTS_PATH}
            loader={restaurantsLoader}
          />
        )}
        <Route
          element={<EmailVerificationSuccessPage />}
          path={EMAIL_VERIFICATION_SUCCESS_PATH}
        />
        <Route
          element={<EmailVerificationFailPage />}
          path={EMAIL_VERIFICATION_FAIL_PATH}
        />
        <Route element={<AccountsLayout />} path={ACCOUNT_ROOT_PATH}>
          <Route element={<Navigate to={ACCOUNT_NOTIFICATIONS_PATH} />} index />
          <Route
            path={ACCOUNT_NOTIFICATIONS_PATH}
            element={<NotificationsPage />}
          />
          <Route
            path={ACCOUNT_BALANCE_PATH}
            element={<BalancePage />}
            loader={userAccountLoader}
          />
          <Route
            path={ACCOUNT_PAYMENT_METHODS_PATH}
            element={<PaymentMethodsPage />}
          />
        </Route>
        <Route element={<FAQPage />} path={FAQ_PATH} />
        <Route element={<IsAuthenticated />} path={PLAID_ROOT_PATH}>
          <Route element={<Navigate to={PLAID_AUTH_TERMINAL_PATH} />} index />
          <Route
            path={PLAID_AUTH_TERMINAL_PATH}
            element={
              <FetchLinkToken>
                <PlaidLink />
              </FetchLinkToken>
            }
          />
          <Route element={<PlaidLink withRedirect />} path={REDIRECT_PATH} />
        </Route>
        <Route
          lazy={async () => {
            const { AboutUsPage } = await import('./aboutUs/AboutUsPage');
            return { Component: AboutUsPage };
          }}
          path={ABOUT_US_PATH}
        />
        <Route
          path={PRIVACY_POLICY_PATH}
          lazy={async () => {
            const { PrivacyPolicyPage } = await import(
              './legal/PrivacyPolicyPage'
            );
            return { Component: PrivacyPolicyPage };
          }}
        />
        <Route
          path={TERMS_OF_SERVICE_PATH}
          lazy={async () => {
            const { TermsOfServicePage } = await import(
              './legal/TermsOfServicePage'
            );
            return {
              Component: TermsOfServicePage,
            };
          }}
        />
        <Route
          path={RESTAURANT_REVENUE_CALCULATOR_PATH}
          lazy={async () => {
            const { RestaurantRevenueCalculator } = await import(
              './calculator/RestaurantRevenueCalculator'
            );
            return {
              Component: RestaurantRevenueCalculator,
            };
          }}
        />
        <Route
          element={
            <RestaurantContextProvider>
              <AvailabilityContextProvider>
                <IsDiscoverable />
              </AvailabilityContextProvider>
            </RestaurantContextProvider>
          }
          id="restaurant"
          loader={restaurantLoader}
          path="/:locationSlug/:nameSlug"
        >
          <Route path="/:locationSlug/:nameSlug">
            <Route
              index
              lazy={async () => {
                const { RestaurantPage } = await import(
                  './restaurant/RestaurantPage'
                );
                return {
                  Component: RestaurantPage,
                };
              }}
            />
            <Route element={<RestaurantAboutPage />} path="about" />
          </Route>
        </Route>
        <Route
          path={CHECKOUT_ROOT_PATH}
          lazy={async () => {
            const { CheckoutLayout } = await import(
              './checkout/CheckoutLayout'
            );
            return {
              element: (
                <RestaurantContextProvider>
                  <AvailabilityContextProvider>
                    <CheckoutLayout />
                  </AvailabilityContextProvider>
                </RestaurantContextProvider>
              ),
            };
          }}
        >
          <Route
            index
            lazy={async () => {
              const { CheckoutPage } = await import('./checkout/CheckoutPage');
              return {
                Component: CheckoutPage,
              };
            }}
          />
          <Route
            path={CHECKOUT_CONFIRMATION_TERMINAL_PATH}
            lazy={async () => {
              const { CheckoutConfirmationPage } = await import(
                './checkout/CheckoutConfirmationPage'
              );
              return {
                Component: CheckoutConfirmationPage,
              };
            }}
          />
        </Route>
        <Route
          element={
            <RestaurantContextProvider>
              <AvailabilityContextProvider>
                <OfferCheckoutLayout />
              </AvailabilityContextProvider>
            </RestaurantContextProvider>
          }
          path={OFFER_CHECKOUT_ROOT_PATH}
        >
          <Route element={<OfferCheckoutPage />} index />
          <Route
            element={<OfferCheckoutConfirmationPage />}
            path={OFFER_CHECKOUT_CONFIRMATION_TERMINAL_PATH}
          />
        </Route>
        <Route element={<ReservationsLayout />} path={RESERVATIONS_ROOT_PATH}>
          <Route
            element={<Navigate replace to={RESERVATIONS_CONFIRMED_PATH} />}
            index
          />
          <Route
            element={<ConfirmedPage />}
            path={RESERVATIONS_CONFIRMED_PATH}
          />
          <Route element={<InactivePage />} path={RESERVATIONS_INACTIVE_PATH} />
        </Route>
        <Route
          element={<ReservationDetailsPage />}
          path={RESERVATION_DETAILS_PATH}
        />
        <Route element={<OffersLayout />} path={OFFERS_ROOT_PATH}>
          <Route element={<Navigate replace to={OFFERS_OUT_PATH} />} index />
          <Route
            element={<OffersOutPage />}
            path={OFFERS_OUT_PATH}
            loader={offersOutLoader}
          />
          <Route element={<OffersInPage />} path={OFFERS_IN_PATH} />
        </Route>
        <Route
          element={<OfferOutDetailsPage />}
          loader={offerLoader}
          path={OFFER_OUT_DETAILS_PATH}
        />
        <Route
          path={RESTAURANT_DEMO_PATH}
          lazy={async () => {
            const { ScheduleDemoPage } = await import(
              './demo/ScheduleDemoPage'
            );
            return {
              Component: ScheduleDemoPage,
            };
          }}
        />
        <Route element={<NotFoundPage />} path="*" />
      </Route>
    </Route>,
  ),
);
