import cx from 'classnames';
import { parseISO, subHours } from 'date-fns';
import { type CancellationPolicy } from '@shared/reservations/types';
import { centsToDollar } from '@shared/utils/currency';
import { toShortDateTime } from '@shared/utils/dateFormatters';
import { useAvailabilityContext } from 'webReservations/context/AvailabilityContext';
import { withCancellationPolicy } from 'webReservations/context/withCancellationPolicy';
import typography from '~styles/typography.scss';
import styles from './OfferCancellationPolicyNotice.scss';

interface WrappedOfferCancellationPolicyNoticeProps {
  date: string;
  cancellationPolicy: CancellationPolicy;
}

const WrappedOfferCancellationPolicyNotice = ({
  date,
  cancellationPolicy,
}: WrappedOfferCancellationPolicyNoticeProps) => {
  const { selectedAvailability } = useAvailabilityContext();

  if (!selectedAvailability) return null;

  const timeBeforeCancellationApplies = subHours(
    parseISO(`${date}T${selectedAvailability.time}`),
    cancellationPolicy.hoursBeforeReservation,
  );

  return (
    <div className={styles.container}>
      <h3 className={typography.h6}>Cancellation Policy</h3>
      <p className={cx(typography.t2, styles.text)}>
        If this offer is accepted, free cancellation before{' '}
        {toShortDateTime(timeBeforeCancellationApplies)} (local to the
        restaurant). After that, cancelling this reservation may trigger a fee
        of {centsToDollar(cancellationPolicy.fee)}
      </p>
    </div>
  );
};

export const OfferCancellationPolicyNotice =
  withCancellationPolicy<WrappedOfferCancellationPolicyNoticeProps>(
    WrappedOfferCancellationPolicyNotice,
  );
