import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { StaticGuestFloorPlan } from 'webReservations/components/StaticGuestFloorPlan';
import { useFloorPlan } from 'webReservations/hooks/useFloorPlan';
import { DetailsPageView } from '../reservationDetails/DetailsPageView';
import { RestaurantDetails } from '../reservationDetails/RestaurantDetails';
import type * as apiHelpers from './apiHelpers';
import { sendGa4OfferDetailViewEvent } from './gaHelpers';
import { OfferDetails } from './OfferDetails';

export const OfferOutDetailsPage = () => {
  const offer = useLoaderData() as apiHelpers.OfferDetails;
  const { floorPlan, isLoading } = useFloorPlan(offer.restaurant.id, false);

  useEffect(() => {
    sendGa4OfferDetailViewEvent({
      restaurantName: offer.restaurant.name,
      publicName: offer.listing.publicName,
      offerAmount: offer.price,
      date: offer.date,
      time: offer.time,
    });
  }, [offer]);

  if (isLoading) {
    return null;
  }

  return (
    <DetailsPageView
      floorPlanComponent={
        <StaticGuestFloorPlan
          backgroundSrc={floorPlan.backgroundSrc}
          tables={floorPlan.floorPlanTables}
          highlightedListingId={offer.listing.id}
        />
      }
      restaurant={offer.restaurant}
      date={offer.date}
      guestCount={offer.guestCount}
      listingPublicName={offer.listing.publicName}
      time={offer.time}
    >
      <OfferDetails offer={offer} />
      <RestaurantDetails restaurant={offer.restaurant} />
    </DetailsPageView>
  );
};
