import { type ReactNode } from 'react';
import { WideView } from '@components/wideView/WideView';
import { IPAD1024 } from '@shared/styles/breakpoints';
import { Layout } from 'webReservations/layout/Layout';
import { ReservationAttributesGrid } from 'webReservations/reservations/ReservationAttributesGrid';
import { ReservationAttributesInline } from 'webReservations/reservations/ReservationAttributesInline';
import { ReservationStatus } from 'webReservations/reservations/ReservationStatus';
import { RestaurantHero } from '../components/RestaurantHero';
import type * as statusHelpers from '../reservations/statusHelpers';
import styles from './DetailsPageView.scss';

interface DetailsViewProps {
  children: ReactNode;
  floorPlanComponent: ReactNode;
  status?: statusHelpers.ReservationStatusType;
  restaurant: {
    address: string;
    id: string;
    name: string;
  };
  date: string;
  guestCount: number;
  listingPublicName: string;
  time: string;
}

export const DetailsPageView = ({
  children,
  floorPlanComponent,
  status,
  restaurant,
  date,
  guestCount,
  listingPublicName,
  time,
}: DetailsViewProps) => (
  <Layout>
    <main className={styles.main}>
      <RestaurantHero restaurant={restaurant}>
        {status && <ReservationStatus status={status} />}
      </RestaurantHero>
      <div className={styles.content}>
        <div className={styles.leftPanel}>
          <WideView
            narrowElement={
              <ReservationAttributesGrid
                containerClassName={styles.reservationAttributesContainer}
                date={date}
                guestCount={guestCount}
                publicName={listingPublicName}
                time={time}
              />
            }
            wideElement={
              <ReservationAttributesInline
                containerClassName={styles.reservationAttributesContainer}
                date={date}
                guestCount={guestCount}
                listingPublicName={listingPublicName}
                time={time}
              />
            }
            breakpoint={IPAD1024}
          />
          <hr />
          <div className={styles.floorPlanContainer}>{floorPlanComponent}</div>
        </div>
        <div className={styles.rightPanel}>{children}</div>
      </div>
    </main>
  </Layout>
);
