import { api } from '@shared/api';
import type { FloorPlanData } from '@shared/types/floorPlans';

export const getFloorPlanForRestaurantId = async (
  restaurantId: string,
  activeListingsOnly: boolean,
) =>
  api.get<FloorPlanData>(
    `/restaurants/${restaurantId}/floor-plan${activeListingsOnly ? '?activeListingsOnly=true' : '?activeListingsOnly=false'}`,
  );
