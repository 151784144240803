import type { ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { todayInTimezone } from '@shared/utils/dateFormatters';
import {
  type Availability,
  type AvailabilityListing,
  getAvailabilities,
  getClosureForDate,
  type RestaurantDetails,
} from '../restaurant/apiHelpers';
import {
  calculateFilteredData,
  getAvailabilitiesGroupedByTime,
} from './availabilityDerivedState';
import {
  availabilityReducer,
  clearSelectedAvailability,
  clearSelectedTime,
  initialAvailabilityState,
  setAvailabilities,
  setSelectedAvailability,
  setSelectedDate,
  setSelectedFloorPlanListingIds,
  setSelectedGuestCount,
  setSelectedTime,
} from './availabilityReducer';
import { useRestaurantContext } from './RestaurantContext';

export interface AvailabilityOption {
  isOffer: boolean;
  isOfferOnly: boolean;
  listing: AvailabilityListing;
  purchasePrice: number | null;
}

export interface TSelectedAvailability {
  isOffer: boolean;
  listingId: string;
  purchasePrice: number | null;
  publicName: string;
  time: string;
}

export interface AvailabilityContextState {
  clearSelectedAvailability: () => void;
  clearSelectedTime: () => void;
  expandedAvailabilityListingIds: string[];
  filteredAvailabilities: Availability[];
  filteredAvailabilitiesGroupedByTime: Record<string, Availability[]>;
  hasAvailabilitiesOnGivenDay: boolean;
  hasUnsupportedGuestCount: boolean;
  isClosedToday: boolean;
  isLoading: boolean;
  isSelectedDateToday: boolean;
  selectedAvailability: TSelectedAvailability | null;
  selectedDate: string;
  selectedFloorPlanListingIds: string[];
  selectedGuestCount: number;
  selectedTime: string | null;
  setSelectedAvailability: (availability: TSelectedAvailability) => void;
  setSelectedDate: (date: string) => void;
  setSelectedFloorPlanListingIds: (ids: string[]) => void;
  setSelectedGuestCount: (guestCount: number) => void;
  setSelectedTime: (time: string) => void;
}

const AvailabilityContext = createContext<AvailabilityContextState | null>(
  null,
);
AvailabilityContext.displayName = 'AvailabilityContext';

export const useAvailabilityContext = () =>
  useDefinedContext(AvailabilityContext);

interface AvailabilityContextProviderProps {
  children: ReactNode;
}

const useIsRestaurantClosedToday = (
  restaurantDetails: RestaurantDetails,
  todayInRestaurantTimezone: string,
) => {
  const { data = [] } = useAbortEffect(
    () =>
      restaurantDetails.id
        ? getClosureForDate(restaurantDetails.id, todayInRestaurantTimezone)
        : Promise.resolve([]),
    [restaurantDetails.id, todayInRestaurantTimezone],
  );
  const isClosedToday = data?.length > 0;

  return isClosedToday;
};

export const AvailabilityContextProvider = ({
  children,
}: AvailabilityContextProviderProps) => {
  const { restaurantDetails } = useRestaurantContext();
  const todayInRestaurantTimezone = useMemo(
    () => todayInTimezone(restaurantDetails.timezone),
    [restaurantDetails.timezone],
  );
  const isClosedToday = useIsRestaurantClosedToday(
    restaurantDetails,
    todayInRestaurantTimezone,
  );
  const [state, dispatch] = useReducer(availabilityReducer, null, () =>
    initialAvailabilityState(restaurantDetails.timezone),
  );
  const {
    availabilities,
    selectedGuestCount,
    selectedTime,
    selectedDate,
    selectedFloorPlanListingIds,
    selectedAvailability,
  } = state;

  const { isPending: isLoading } = useAbortEffect(async () => {
    const allAvailabilities = await getAvailabilities(
      restaurantDetails.id,
      selectedDate,
    );
    dispatch(setAvailabilities(allAvailabilities));
  }, [selectedDate, restaurantDetails]);

  const {
    filteredAvailabilities,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  } = calculateFilteredData(state, restaurantDetails.timezone);

  const filteredAvailabilitiesGroupedByTime = getAvailabilitiesGroupedByTime(
    state,
    restaurantDetails.timezone,
  );

  const isSelectedDateToday =
    selectedDate === todayInTimezone(restaurantDetails.timezone);

  const value = useMemo<AvailabilityContextState>(
    () => ({
      clearSelectedAvailability: () => dispatch(clearSelectedAvailability()),
      clearSelectedTime: () => dispatch(clearSelectedTime()),
      filteredAvailabilities,
      filteredAvailabilitiesGroupedByTime,
      hasAvailabilitiesOnGivenDay: !!availabilities.length,
      hasUnsupportedGuestCount,
      isClosedToday,
      isLoading,
      selectedAvailability,
      selectedDate,
      selectedGuestCount,
      selectedFloorPlanListingIds,
      selectedTime,
      setSelectedAvailability: (availability: TSelectedAvailability) =>
        dispatch(setSelectedAvailability(availability)),
      setSelectedDate: (date: string) => dispatch(setSelectedDate(date)),
      setSelectedFloorPlanListingIds: (floorPlanListingIds: string[]) =>
        dispatch(setSelectedFloorPlanListingIds(floorPlanListingIds)),
      setSelectedGuestCount: (guestCount: number) =>
        dispatch(setSelectedGuestCount(guestCount)),
      setSelectedTime: (time: string) => dispatch(setSelectedTime(time)),
      expandedAvailabilityListingIds,
      isSelectedDateToday,
    }),
    [state],
  );

  return (
    <AvailabilityContext.Provider value={value}>
      {children}
    </AvailabilityContext.Provider>
  );
};
