import { type CancellationPolicy } from '@shared/reservations/types';
import { type ApiResponse } from '@shared/types/apiHelpers';
import { type IconNameType } from '@shared/types/floorPlans';
import { type RestaurantStatus } from '@shared/types/restaurants';
import { api } from '../api';
import DeprecatedAPI from '../api/apiClient';

export interface RestaurantDetails {
  address: string;
  cancellationPolicy: CancellationPolicy | null;
  city: string;
  description: string;
  id: string;
  locationSlug: string;
  maxReservationGuests: number;
  name: string;
  nameSlug: string;
  phone: string;
  postalCode: string;
  schedule: number;
  state: string;
  status: RestaurantStatus;
  street: string;
  timezone: string;
  website: string;
}

export interface Restaurant {
  address: string;
  city: string;
  description: string;
  id: string;
  locationSlug: string;
  maxReservationGuests: 0;
  name: string;
  nameSlug: string;
  phone: string;
  postalCode: string;
  schedule: number;
  state: string;
  status: string;
  street: string;
  timezone: string;
  website: string;
}

export interface AvailabilityListing {
  iconName: IconNameType;
  id: string;
  isCommunal: boolean;
  publicName: string;
}

export interface Availability {
  isBiddable: boolean;
  isBuyable: boolean;
  guestCount: number;
  purchasePrice: number | null;
  listing: AvailabilityListing;
  time: string;
}

export interface RestaurantEvent {
  description: string;
  name: string;
}

export interface Closure {
  date: string;
  id: string;
  restaurantId: string;
}

export interface FloorPlanTableAvailability {
  startTime: string;
  endTime: string;
  minGuest: number;
  maxGuest: number;
}

export type FloorPlanTableAvailabilitiesResponse = Record<
  string, // '2024-10-23'
  FloorPlanTableAvailability[]
>;

export const getRestaurants = async (): Promise<ApiResponse<Restaurant[]>> => {
  const response = await DeprecatedAPI.get(`/restaurants`);
  return response.json();
};

export const getRestaurantDetailsBySlug = async (
  locationSlug: string,
  nameSlug: string,
): Promise<ApiResponse<RestaurantDetails>> => {
  const response = await DeprecatedAPI.get(
    `/restaurants/${locationSlug}/${nameSlug}`,
  );
  return response.json();
};

export const getRestaurantDetailsById = async (
  restaurantId: string,
): Promise<ApiResponse<RestaurantDetails>> => {
  const response = await DeprecatedAPI.get(`/restaurants/${restaurantId}`);
  return response.json();
};

export const getAvailabilities = async (
  restaurantId: string,
  date: string,
): Promise<Availability[]> => {
  const response = await DeprecatedAPI.get(
    `/restaurants/${restaurantId}/availabilities/${date}`,
  );

  return response.json();
};

export const getEventForRestaurantIdAndDate = async (
  restaurantId: string,
  date: string,
): Promise<RestaurantEvent | undefined> =>
  api.get(`/restaurants/${restaurantId}/events/${date}`);

export const getClosureForDate = async (restaurantId: string, date: string) =>
  api.get<Closure[]>(`/closures/${restaurantId}?date=${date}`);

export const getFloorPlanTableAvailabilities = async (
  restaurantId: string,
  floorPlanTableId: string,
  date: string,
): Promise<FloorPlanTableAvailabilitiesResponse> =>
  api.get(
    `/restaurants/${restaurantId}/floor-plan-tables/${floorPlanTableId}/availabilities?date=${date}`,
  );
