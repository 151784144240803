import { type FeatureConfig } from '@shared/context/FeatureFlagContext';
import { type Environment } from '@shared/environments';

// Add new features modify FeatureFlag type
export type WebReservationsFeatureFlag = 'testFlag' | 'stripeAsyncCapture';

export const featureFlagConfig: Readonly<
  Record<Environment, FeatureConfig<WebReservationsFeatureFlag>>
> = Object.freeze({
  development: {
    testFlag: true,
    stripeAsyncCapture: true,
  },
  ci: {
    testFlag: true,
    stripeAsyncCapture: true,
  },
  staging: {
    testFlag: true,
    stripeAsyncCapture: true,
  },
  demo: {
    testFlag: false,
    stripeAsyncCapture: true,
  },
  production: {
    testFlag: false,
    stripeAsyncCapture: true,
  },
});
