import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { type AsyncHookData } from '@shared/types/hooks';
import { getFloorPlanForRestaurantId } from 'webReservations/floorPlans/apiHelpers';

export const useFloorPlan = (
  restaurantId: string,
  activeListingsOnly = false,
): AsyncHookData<'floorPlan', FloorPlanData> => {
  const [isLoading, setIsLoading] = useState(true);
  const [floorPlan, setFloorPlan] = useState<FloorPlanData>({
    id: 'not-loaded',
    backgroundSrc: undefined,
    floorPlanTables: [],
  });

  const fetchFloorPlan = async () => {
    try {
      const floorPlanResponse = await getFloorPlanForRestaurantId(
        restaurantId,
        activeListingsOnly,
      );
      setFloorPlan(floorPlanResponse);
    } catch (error) {
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchFloorPlan();
  }, []);

  return isLoading
    ? { floorPlan: undefined, isLoading }
    : { floorPlan, isLoading };
};
