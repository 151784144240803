import cx from 'classnames';
import { Button, ButtonVariants } from '@components/button/Button';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { centsToWholeDollar } from '@utils/currency';
import { formatTimeRemaining } from 'webReservations/utils/formatTimeRemaining';
import typography from '~styles/typography.scss';
import { useTimer } from '../hooks/useTimer';
import { AcceptTopOfferModal } from '../offers/AcceptTopOfferModal';
import { sendGa4OfferAcceptStartEvent } from '../offers/gaHelpers';
import styles from './AcceptTopOffer.scss';
import type { ReservationWithDetails, TopOffer } from './apiHelpers';

export interface AcceptTopOfferProps {
  offer?: TopOffer;
  reservation: ReservationWithDetails;
}

export const AcceptTopOffer = ({ offer, reservation }: AcceptTopOfferProps) => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();
  const timeRemaining = useTimer(offer);

  const MINIMUM_OFFER_TIME_REMAINING = 1;

  const hasOffer = offer !== undefined && Object.keys(offer).length > 0;

  return (
    <>
      <div className={styles.container}>
        <h3 className={typography.h7m}>Accept Top Offer</h3>
        {hasOffer && (
          <Button
            className={styles.sellNowButton}
            isDisabled={
              timeRemaining !== undefined &&
              timeRemaining < MINIMUM_OFFER_TIME_REMAINING
            }
            label={`Sell now for ${centsToWholeDollar(offer.price)}`}
            onClick={() => {
              sendGa4OfferAcceptStartEvent({
                date: reservation.date,
                offerAmount: offer.price,
                publicName: reservation.listing.publicName,
                restaurantName: reservation.restaurant.name,
                time: reservation.time,
              });
              openModal();
            }}
            variant={ButtonVariants.Primary}
          />
        )}
        {hasOffer && timeRemaining && (
          <div className={styles.expiration}>
            <div className={cx(typography.t3, styles.expirationLabel)}>
              expiring
            </div>
            <div className={cx(typography.h8, styles.topOfferPill)}>
              {formatTimeRemaining(timeRemaining)}
            </div>
          </div>
        )}
        {!hasOffer && (
          <div className={cx(typography.h8, styles.noOfferPill)}>
            No offers yet
          </div>
        )}
      </div>
      {hasOffer && (
        <AcceptTopOfferModal
          closeModal={closeModal}
          isOpen={isModalOpen}
          offer={offer}
          reservation={reservation}
        />
      )}
    </>
  );
};
