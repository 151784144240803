// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmptyState__container___zYPmY{align-items:center;align-self:center;display:flex;flex-direction:column;justify-content:center;text-align:center}.EmptyState__title___pkW9A{margin-top:128px;margin-bottom:0;text-wrap:balance}.EmptyState__subtitle___jEQw7{color:var(--white70);margin-top:16px;margin-bottom:0;text-wrap:balance}", "",{"version":3,"sources":["webpack://./src/webReservations/components/EmptyState.scss"],"names":[],"mappings":"AAAA,+BACE,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,iBAAA,CAGF,2BACE,gBAAA,CACA,eAAA,CACA,iBAAA,CAGF,8BACE,oBAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EmptyState__container___zYPmY",
	"title": "EmptyState__title___pkW9A",
	"subtitle": "EmptyState__subtitle___jEQw7"
};
export default ___CSS_LOADER_EXPORT___;
