import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { parseToDayMonthFormat } from '@shared/utils/dateFormatters';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import styles from './ReservationAttributesInline.scss';

interface ReservationAttributesLayoutProps {
  containerClassName?: string;
  date: string;
  guestCount: number;
  listingPublicName: string;
  time: string;
}

export const ReservationAttributesInline = ({
  containerClassName,
  date,
  guestCount,
  listingPublicName,
  time,
}: ReservationAttributesLayoutProps) => (
  <div className={cx(styles.container, containerClassName)}>
    <div className={cx(styles.attribute)}>
      <Icon name="calendar" className={styles.icon} />
      <span className={typography.t1}>{parseToDayMonthFormat(date)}</span>
    </div>
    <div className={cx(styles.attribute)}>
      <Icon name="clock" className={styles.icon} />
      <span className={typography.t1}>{ISOTimeTo12HourTime(time)}</span>
    </div>
    <div className={cx(styles.attribute)}>
      <Icon name="couple" className={styles.icon} />
      <span className={typography.t1}>{guestCount}</span>
    </div>
    <div className={cx(styles.attribute)}>
      <Icon name="table" className={styles.icon} />
      <span className={cx(typography.t1, styles.table)}>
        {listingPublicName}
      </span>
    </div>
  </div>
);
