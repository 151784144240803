import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { centsToWholeDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import { AcceptTopOfferModal } from '../offers/AcceptTopOfferModal';
import { sendGa4OfferAcceptStartEvent } from '../offers/gaHelpers';
import { getTopOffer, type TopOffer } from '../reservationDetails/apiHelpers';
import { type Reservation } from './apiHelpers';
import styles from './ReservationCardActions.scss';
import { UpdateListPriceModal } from './UpdateListPriceModal';

export interface ReservationCardActionsProps {
  buyNowPrice: number | null;
  fetchConfirmedReservations: () => Promise<void>;
  reservation: Reservation;
}

export const ReservationCardActions = ({
  buyNowPrice,
  fetchConfirmedReservations,
  reservation,
}: ReservationCardActionsProps) => {
  const [topOffer, setTopOffer] = useState<TopOffer>();
  const {
    close: closeAcceptTopOfferModal,
    isOpen: isAcceptTopOfferModalOpen,
    open: openAcceptTopOfferModal,
  } = useIsOpen();
  const {
    close: closeUpdateListPriceModal,
    isOpen: isUpdateListPriceModalOpen,
    open: openUpdateListPriceModal,
  } = useIsOpen();

  useAbortEffect(async (signal) => {
    const response = await getTopOffer(reservation.id, signal);

    try {
      setTopOffer(response);
    } catch (error) {
      reportAppError(error);
    }
  }, []);

  const hasOffer = !!topOffer?.id;
  const hasActions = buyNowPrice || hasOffer;

  return (
    <div className={styles.container} data-testid="card-actions">
      {hasActions ? (
        <>
          <div className={styles.sellNowContainer}>
            {hasOffer ? (
              <>
                <Button
                  className={typography.h8}
                  label={`Sell now for ${centsToWholeDollar(topOffer.price)}`}
                  onClick={() => {
                    sendGa4OfferAcceptStartEvent({
                      date: reservation.date,
                      offerAmount: topOffer.price,
                      publicName: reservation.listing.publicName,
                      restaurantName: reservation.restaurant.name,
                      time: reservation.time,
                    });
                    openAcceptTopOfferModal();
                  }}
                  variant={ButtonVariants.Primary}
                />
                <AcceptTopOfferModal
                  closeModal={closeAcceptTopOfferModal}
                  isOpen={isAcceptTopOfferModalOpen}
                  offer={topOffer}
                  reservation={reservation}
                />
              </>
            ) : null}
          </div>
          {buyNowPrice && (
            <>
              <Button
                className={cx(typography.h7, styles.listPriceButton)}
                label={`List Price ${centsToWholeDollar(buyNowPrice)}`}
                onClick={openUpdateListPriceModal}
                variant={ButtonVariants.Default}
              />
              <UpdateListPriceModal
                closeModal={closeUpdateListPriceModal}
                isOpen={isUpdateListPriceModalOpen}
                listPrice={buyNowPrice}
                onSubmit={fetchConfirmedReservations}
                reservationId={reservation.id}
              />
            </>
          )}
        </>
      ) : null}
    </div>
  );
};
