// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AcceptTopOffer__container___DpI82{display:flex;flex-direction:column;gap:1.25rem;padding:1rem}.AcceptTopOffer__container___DpI82 h3{margin:0}.AcceptTopOffer__container___DpI82 .AcceptTopOffer__sellNowButton___AszQ7{height:2rem}.AcceptTopOffer__container___DpI82 .AcceptTopOffer__expiration___iO1da{align-items:center;display:flex;flex-direction:row}.AcceptTopOffer__container___DpI82 .AcceptTopOffer__expiration___iO1da .AcceptTopOffer__expirationLabel___oO6cA{letter-spacing:1px;margin-right:10px;text-transform:uppercase}.AcceptTopOffer__container___DpI82 .AcceptTopOffer__expiration___iO1da .AcceptTopOffer__topOfferPill___ctbE0{background-color:var(--secondary300);border-radius:100px;padding:4px 8px}.AcceptTopOffer__container___DpI82 .AcceptTopOffer__noOfferPill___Lfh79{background-color:var(--secondary300);border-radius:100px;margin-top:0;padding:.25rem .5rem}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/AcceptTopOffer.scss"],"names":[],"mappings":"AAEA,mCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA,CAEA,sCACE,QAAA,CAGF,0EACE,WAAA,CAGF,uEACE,kBAAA,CACA,YAAA,CACA,kBAAA,CAEA,gHACE,kBAAA,CACA,iBAAA,CACA,wBAAA,CAGF,6GACE,oCAAA,CACA,mBAAA,CACA,eAAA,CAIJ,wEACE,oCAAA,CACA,mBAAA,CACA,YAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AcceptTopOffer__container___DpI82",
	"sellNowButton": "AcceptTopOffer__sellNowButton___AszQ7",
	"expiration": "AcceptTopOffer__expiration___iO1da",
	"expirationLabel": "AcceptTopOffer__expirationLabel___oO6cA",
	"topOfferPill": "AcceptTopOffer__topOfferPill___ctbE0",
	"noOfferPill": "AcceptTopOffer__noOfferPill___Lfh79"
};
export default ___CSS_LOADER_EXPORT___;
